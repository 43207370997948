import React from "react";
import { AuthProvider } from "./context";
import { Route, Routes } from "react-router-dom";
import { routes } from "./routes";

function App() {
  return (
    <AuthProvider>
      <Routes>
        {routes.map((route, index) => (
          <Route
            key={index}
            path={route.path}
            element={route.element}
            Component={route.Component}
          />
        ))}
      </Routes>
    </AuthProvider>
  );
}

export default App;
