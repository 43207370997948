import React from "react";
import Medias from "../global/Medias";
import { useAuth } from "../context";

export default function Profile() {
  const { userCredentials } = useAuth();

  return (
    <div className="app-content-area animate-left">
      <div className="container-fluid">
        <div className="row align-items-center">
          <div className="col-xl-12 col-lg-12 col-md-12 col-12 mt-5">
            <div className="card rounded-bottom smooth-shadow-sm">
              <div className="d-flex align-items-center justify-content-between pt-4 pb-6 px-4">
                <div className="d-flex align-items-center">
                  <div className="avatar-xxl avatar-indicators avatar-online me-2 position-relative d-flex justify-content-end align-items-end mt-n10 rounded-circle bg-white">
                    <img
                      src={Medias.user}
                      className="avatar-xxl rounded-circle border border-2 "
                      alt="01"
                    />
                  </div>
                  <div className="lh-1">
                    <h2 className="mb-0">{userCredentials.username}</h2>
                    <p className="mb-0 d-block">@{userCredentials.username}</p>
                  </div>
                </div>
                <div>
                  <span className="d-none d-md-block">
                    <i className="icofont-duotone icofont-shield" />{" "}
                    {userCredentials.role}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="py-6">
          <div className="row">
            <div className="col-xl-6 col-lg-6 col-md-12 col-12 mb-5">
              <div className="row mb-8">
                <div className="col-xl-3 col-lg-4 col-md-12 col-12">
                  <div className="mb-4 mb-lg-0">
                    <h4 className="mb-1">
                      
                    </h4>
                    <p className="mb-0 fs-5 text-muted">
                      
                    </p>
                  </div>
                </div>

                <div className="col-xl-9 col-lg-8 col-md-12 col-12">
                  <div className="card" id="preferences">
                    <div className="card-body">
                      <div className="mb-6">
                        <h4 className="mb-1">
                          <i className="icofont-duotone icofont-shield" />{" "}
                          
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
}
