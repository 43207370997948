function formatDateToDDMMYYYY(isoDateString) {
  const date = new Date(isoDateString);

  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Les mois commencent à 0
  const year = date.getFullYear();

  return `${day}-${month}-${year}`;
}

function formatDateToYYYYMMDD(isoDateString) {
  const date = new Date(isoDateString);

  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Les mois commencent à 0
  const year = date.getFullYear();

  return `${year}-${month}-${day}`;
}

function formatDateToYYYYMMDDH(isoDateString) {
  const date = new Date(isoDateString);

  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Les mois commencent à 0
  const year = date.getFullYear();

  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}

export {formatDateToDDMMYYYY, formatDateToYYYYMMDD, formatDateToYYYYMMDDH}
// const formattedDate = formatDateToDDMMYYYY('2024-09-10T00:00:00.000Z');
// console.log(formattedDate);
