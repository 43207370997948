import auth_background from "../assets/login-cover.jpg";
import sidbar_background from "../assets/sidbar-design.png";
import auth_icon from "../assets/auth.svg";
import logo_default from "../assets/logo-app-track.png";
import logo_sid_bar from "../assets/sidbar-logo-allwhite.png";
import user from "../assets/user.png";
import userWhite from "../assets/user-white.png";
import qrcodegif from "../assets/qr-code.gif";

import solo from "../assets/qrcode/solo.png";
import awlsolo from "../assets/qrcode/awl6-solo.png";
import awlcouple from "../assets/qrcode/awl6-couple.png";
import awlgroupe from "../assets/qrcode/awl6-groupe.png";
import groupe from "../assets/qrcode/groupe.png";
import couple from "../assets/qrcode/couple.png";

import partern from "../assets/parten.svg";
import category from "../assets/category.png";

const Medias = {
  sidbar_background,
  auth_background,
  auth_icon,
  logo_default,
  logo_sid_bar,
  user,
  userWhite,
  qrcodegif,
  solo,
  awlsolo,
  groupe,
  couple,
  awlcouple,
  awlgroupe,
  partern,
  category
};
export default Medias;
