import React, { useEffect } from "react";
import Medias from "../global/Medias";
import { Link, useLocation } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";

export default function SidBar() {
  const location = useLocation();

  // Fonction pour vérifier si la route est active
  const isActive = (path) => location.pathname === path;

  // Titre dynamique basé sur la route
  const routeTitles = {
    "/": "Tableau de bord",
    "/roles": "Roles",
    "/permissions": "Permissions",
    "/users": "Utilisateurs",
    "/settings": "Profile",
    "/category": "Categories",
    "/sub-category": "Sous catégories d'articles",
    "/produces": "Articles",
    "/payements-methodes": "Moyens de payement",
    "/celling": "Boutique",
    "/celling-repport": "Rapport - Vente",
    "/aprov-repport": "Rapport - Approvissionnement",
  };

  // Mettre à jour le titre de la fenêtre en fonction de l'URL active
  useEffect(() => {
    const activeTitle = routeTitles[location.pathname] || "T";
    document.title = `BUS VANILLA | ${activeTitle}`;
  }, [location.pathname]);

  return (
    <div className="app-menu">
      <div className="navbar-vertical navbar nav-dashboard">
        <div className="h-100" data-simplebar="" style={{ overflowY: "auto" }}>
          <PerfectScrollbar>
            {/* Brand logo */}
            <Link className="navbar-brand" to="/">
              <img src={Medias.logo_sid_bar} alt="bus-logo" />
            </Link>

            {/* Navbar nav */}
            <ul className="navbar-nav flex-column" id="repportSidbar">
              {/* Tableau de bord */}
              <li
                className={`nav-item ${
                  isActive("/") ? "active-current-text" : ""
                }`}
              >
                <Link className="nav-link has-arrow collapsed" to="/">
                  <i className="bi bi-house"></i>&nbsp; Tableau de bord
                </Link>
              </li>

              <li
                className={`nav-item ${
                  isActive("/celling") ? "active-current-text" : ""
                }`}
              >
                <Link className="nav-link has-arrow collapsed" to="/celling">
                  <i className="bi bi-cart"></i>&nbsp; Boutique
                </Link>
              </li>

              {/* Repports */}

              <li className="nav-item">
                <Link
                  className="nav-link has-arrow collapsed"
                  to="#!"
                  data-bs-toggle="collapse"
                  data-bs-target="#repports-collapsed"
                  aria-expanded="false"
                  aria-controls="repports-collapsed"
                >
                  <i className="bi bi-clipboard-data"></i>&nbsp; Rapports
                </Link>
                <div
                  id="repports-collapsed"
                  className="collapse"
                  data-bs-parent="#repportSidbar"
                >
                  <ul className="nav flex-column">
                    <li
                      className={`nav-item ${
                        isActive("/celling-repport")
                          ? "active-current-text"
                          : ""
                      }`}
                    >
                      <Link
                        className="nav-link has-arrow"
                        to="/celling-repport"
                      >
                        Vente
                      </Link>
                    </li>

                    <li
                      className={`nav-item ${
                        isActive("/aprov-repport") ? "active-current-text" : ""
                      }`}
                    >
                      <Link className="nav-link has-arrow" to="/aprov-repport">
                        Aprovisionnement
                      </Link>
                    </li>
                  </ul>
                </div>
              </li>

              {/* Articles */}
              <li className="nav-item">
                <div className="navbar-heading">Articles</div>
              </li>
              <li
                className={`nav-item ${
                  isActive("/category") ? "active-current-text" : ""
                }`}
              >
                <Link className="nav-link has-arrow" to="/category">
                  <i className="bi bi-grid"></i>&nbsp; Categories
                </Link>
              </li>
              <li
                className={`nav-item ${
                  isActive("/sub-category") ? "active-current-text" : ""
                }`}
              >
                <Link className="nav-link has-arrow" to="/sub-category">
                  <i className="bi bi-tags"></i>&nbsp; Sous Catégories
                </Link>
              </li>
              <li
                className={`nav-item ${
                  isActive("/produces") ? "active-current-text" : ""
                }`}
              >
                <Link className="nav-link" to="/produces">
                  <i className="bi bi-backpack3"></i>&nbsp; Articles
                </Link>
              </li>

              {/* Autre parametre */}
              <li className="nav-item">
                <div className="navbar-heading">Autre prametre</div>
              </li>
              <li
                className={`nav-item ${
                  isActive("/payements-methodes") ? "active-current-text" : ""
                }`}
              >
                <Link className="nav-link" to="/payements-methodes">
                  <i className="bi bi-piggy-bank"></i>&nbsp; Moyens de payement
                </Link>
              </li>

              {/* Parametre système */}
              <li className="nav-item">
                <div className="navbar-heading">Prametre système</div>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link has-arrow collapsed"
                  to="#!"
                  data-bs-toggle="collapse"
                  data-bs-target="#navblog"
                  aria-expanded="false"
                  aria-controls="navblog"
                >
                  <i className="icofont-duotone icofont-shield"></i>&nbsp; Accès
                  utilisateur
                </Link>
                <div
                  id="navblog"
                  className="collapse"
                  data-bs-parent="#sideNavbar"
                >
                  <ul className="nav flex-column">
                    <li
                      className={`nav-item ${
                        isActive("/roles") ? "active-current-text" : ""
                      }`}
                    >
                      <Link className="nav-link has-arrow" to="/roles">
                        Roles
                      </Link>
                    </li>
                    <li
                      className={`nav-item ${
                        isActive("/permissions") ? "active-current-text" : ""
                      }`}
                    >
                      <Link className="nav-link has-arrow" to="/permissions">
                        Permissions
                      </Link>
                    </li>
                    <li
                      className={`nav-item ${
                        isActive("/users") ? "active-current-text" : ""
                      }`}
                    >
                      <Link className="nav-link has-arrow" to="/users">
                        Utilisateurs
                      </Link>
                    </li>
                  </ul>
                </div>
              </li>

              {/* Profile */}
              <li
                className={`nav-item ${
                  isActive("/settings") ? "active-current-text" : ""
                }`}
              >
                <Link className="nav-link has-arrow" to="/settings">
                  <i className="icofont-duotone icofont-user"></i>&nbsp; Profile
                </Link>
              </li>
            </ul>

            {/* Section supplémentaire */}
            <div className="card bg-light shadow-none text-center mx-4 my-8">
              <div className="card-body">
                <img
                  src="../assets/images/background/premium.png"
                  alt="bus"
                  style={{ height: "55px" }}
                />
                <div className="mt-4">
                  <h5>Unlimited Access</h5>
                  <p className="fs-6">
                    <Link
                      target="_blank"
                      to="https://www.linkedin.com/in/ndfranz"
                    >
                      NDFranz
                    </Link>{" "}
                    C-CMS Premium access for
                    <Link to="#" className="__cf_email__" target="_blank">
                      {" "}
                      C1
                    </Link>
                  </p>
                  <Link
                    to="mailto:napoukinedamedjate@gmail.com"
                    className="btn btn-secondary btn-sm"
                  >
                    <i className="bi bi-envelope-at"></i> Help
                  </Link>
                </div>
              </div>
            </div>
          </PerfectScrollbar>
        </div>
      </div>
    </div>
  );
}
