import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

export default function Loader() {
  const [isBlock, setIsBlock] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setIsBlock(true);
    }, 5000);
  }, [isBlock]);

  return (
    <div className="loaderApp">
      <div className="lds-roller">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      {isBlock && (
        <Link className="btn btn-outline-primary btn-sm" to="/">
          <i className="fas fa-redo"></i> Repartir
        </Link>
      )}
    </div>
  );
}
