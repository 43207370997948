import React from "react";

export default function CompoLoader() {
  return (
    <div className="bus-component-loader rounded">
      <div className="lds-roller">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
}
