import React, { useContext } from "react";
import { ThemeContext } from "../ThemeContext";

const ThemeSwitcher = () => {
  const { theme, updateTheme } = useContext(ThemeContext);

  const handleClick = (event) => {
    const newTheme = event.currentTarget.getAttribute("data-bs-theme-value");
    updateTheme(newTheme);
  };

  return (
    <div>
      <div className="btn-group">
        <button
          title="Theme claire"
          type="button"
          className={`btn btn-outline-white ${
            theme === "light" ? "bg-light" : ""
          } btn-sm`}
          data-bs-theme-value="light"
          onClick={handleClick}
        >
          <i className="bx bx-sun"></i>
        </button>
        <button
          title="Theme sombre"
          type="button"
          className={`btn btn-outline-white ${
            theme === "dark" ? "bg-light" : ""
          } btn-sm`}
          data-bs-theme-value="dark"
          onClick={handleClick}
        >
          <i className="bx bx-moon"></i>
        </button>
        <button
          title="Theme système"
          type="button"
          className={`btn btn-outline-white ${
            theme === "auto" ? "bg-light" : ""
          } btn-sm`}
          data-bs-theme-value="auto"
          onClick={handleClick}
        >
          <i className="bi bi-circle-half"></i>
        </button>
      </div>
    </div>
  );
};

export default ThemeSwitcher;
