import React, { useState } from "react";
import Medias from "../../global/Medias";
import { Link } from "react-router-dom";

export default function ProducesManager({
  handleCancelStocker,
  handleCancelForm,
}) {
  const [showDetailsOfResult, setShowDetailsOfResult] = useState(false);

  const handleShowDetails = () => setShowDetailsOfResult(true);
  const handleHideDetails = () => setShowDetailsOfResult(false);

  const SearchBar = () => (
    <div className="col-6 d-flex gap-3">
      <div className="input-group">
        <input
          className="form-control rounded-3 bg-transparent ps-9"
          type="search"
          id="searchInput"
          placeholder="Rechercher l'article par: sous-catégorie ou nom"
        />
        <div className="btn position-absolute start-0">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="15"
            height="15"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="feather feather-search text-dark"
          >
            <circle cx="11" cy="11" r="8"></circle>
            <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
          </svg>
        </div>
      </div>
      <button type="submit" className="btn btn-outline-success">
        Rechercher
      </button>
    </div>
  );

  const TableView = () => (
    <div className="table-responsive">
      <table className="table table-striped table-sm mt-5">
        <thead>
          <tr>
            <th>Désignation</th>
            <th>Sous-catégorie</th>
            <th>Stock</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <Link onClick={handleShowDetails} to="#" className="text-dark">
                Lorem sla
              </Link>
            </td>
            <td>Lorem</td>
            <td>20 000</td>
          </tr>
          <tr>
            <td>
              <Link to="#" className="text-dark">
                Lorem2 sla
              </Link>
            </td>
            <td>Lorem 2</td>
            <td>25 000</td>
          </tr>
        </tbody>
      </table>
    </div>
  );

  const DetailsView = () => (
    <form
      className="row mt-5 needs-validation ndf-transimssion"
      onSubmit={handleCancelStocker}
    >
      <div className="col-12 d-md-flex">
        <div
          className="col-md-6 col-12 d-flex p-2 rounded"
          style={{
            height: "400px",
            width: "400px",
            background: `url(${Medias.partern})`,
          }}
        >
          <img
            src="http://localhost:9075/images/subcategory/BUS1733940486890-C720262265.png"
            className="rounded"
            style={{ objectFit: "cover", height: "100%", width: "100%" }}
            alt=""
          />
        </div>
        <div className="col ps-md-10 ps-0">
          <h3>Product Title Name</h3>
          <div>
            <span className="me-2 text-dark">
              <i className="bi bi-tags text-success"></i>
            </span>
            592 Customer Reviews
          </div>
          <hr className="my-3" />
          <div className="d-flex justify-content-between">
            <h3 className="mb-1 col-md-6 col-12">
              <span className="text-muted">
                <i className="bi bi-piggy-bank"></i> Prix
              </span>
              <span className="text-danger"> 25 000</span>
            </h3>
            <h3 className="mb-1">
              <span className="text-muted">
                <i className="bi bi-inboxes"></i> Stock valid:
              </span>
              <span className="text-danger"> 24</span>
            </h3>
          </div>
          <div className="border-top py-4">
            <div className="border d-inline-block px-2 py-1 rounded-pill mb-3">
              <span className="text-dark">
                Description{" "}
                <i className="bi bi-star-fill text-success fs-6"></i>
              </span>
            </div>
            <p>
              Quality is more than good that I was expected for buying. I first
              time purchase Dash UI shoes & this brand is good. Thanks to Dash
              UI delivery was faster than fast ...
            </p>
            <span>12/12/24</span>
          </div>
          <hr />
          <div className="form-group col-md-8 col-12">
            <label htmlFor="aprovValue">Valeur d'approvisionnement</label>
            <input
              type="number"
              name="aprovValue"
              id="aprovValue"
              className="form-control"
              min={1}
            />
          </div>
        </div>
      </div>
      <div className="col-12 justify-content-end mt-5 d-flex gap-3">
        <button
          className="btn btn-outline-secondary"
          type="button"
          title="Revenir au resultat de recherche"
          onClick={handleHideDetails}
        >
          <i className="bi bi-arrow-clockwise"></i> Annuler
        </button>
        <button
          className="btn btn-success"
          type="submit"
          title="Enregistrer le stock"
        >
          <i className="bi bi-save"></i> Valider
        </button>
      </div>
    </form>
  );

  return (
    <div className="col-12 animate-left">
      <div className="card">
        <div className="card-header d-md-flex border-bottom border-bottom-dark">
          <div className="col-md-6">Parametres des stock</div>
          <div className="col-md-6 d-flex justify-content-end">
            <button
              type="button"
              onClick={handleCancelForm}
              className="btn btn-text btn-sm text-danger"
              title="Annuler le formulaire et retourner à la liste"
            >
              <i className="icofont-close-circled"></i> Annuler
            </button>
          </div>
        </div>
        <div className="card-body">
          <SearchBar />
          {!showDetailsOfResult ? <TableView /> : <DetailsView />}
        </div>
      </div>
    </div>
  );
}
