import React, { useEffect, useState } from "react";
import Medias from "../global/Medias";
import { Link, useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import { useAuth } from "../context";
import Loader from "../layouts/Loader";
import CompoLoader from "../layouts/CompoLoader";
import ThemeSwitcher from "../global/ThemeSwitcher";

export default function Login() {
  const [loading, setLoading] = useState(true);
  const navigation = useNavigate();
  const { validateUsername, validatePassword } = useAuth();
  const [values, setValues] = useState({
    username: "",
    password: "",
    rememberMe: false,
  });
  const [userCrentials, setUserCredentials] = useState({
    password: "",
    user_id: "",
  });
  const [isFistStep, setIsFirstStep] = useState(true);
  const [isLoadingForm, setIsLoadingForm] = useState(false);

  /**
   * Handle log in with email and password
   * @param {object} e DOM event
   * @returns void
   */
  const handleValidateUsername = async (e) => {
    try {
      e.preventDefault();
      if (values.username === "") {
        return toast.error("Please field your credentials!", {
          position: "top-right",
          duration: 6000,
        });
      }
      setIsLoadingForm(true);
      /**
       * Send creadentials to context
       */
      const validateUsernameReq = await validateUsername(values);
      setUserCredentials({
        ...userCrentials,
        user_id: validateUsernameReq.user_id,
      });
      setIsLoadingForm(false);
      setIsFirstStep(false);
    } catch (error) {
      if (error.code === "ERR_NETWORK") {
        toast.error("Wrong credential(s)!", {
          position: "top-right",
          duration: 9000,
        });
      } else {
        toast.error(error.response?.data?.message, {
          position: "top-right",
          duration: 9000,
        });
      }
      setIsLoadingForm(false);
      setIsFirstStep(true);
    }
  };

  const handleValidatePassword = async (e) => {
    try {
      e.preventDefault();
      if (values.password === "") {
        return toast.error("Please field your credentials!", {
          position: "top-right",
          duration: 6000,
        });
      }
      // setIsFirstStep(false);
      setIsLoadingForm(true);
      /**
       * Send creadentials to context
       */
      let obj = {
        password: values.password,
        user_id: userCrentials.user_id,
      };
      await validatePassword(obj);
      setIsLoadingForm(false);
    } catch (error) {
      toast.error("Wrong credential(s)!", { position: "top-right" });
      setIsLoadingForm(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    let currentUser = JSON.parse(sessionStorage.getItem("user"));
    document.title = `BUS VANILLA | Authentification`;
    if (currentUser === null) {
      setLoading(false);
    } else {
      navigation("/", { replace: true });
    }
  }, [navigation]);

  return loading ? (
    <Loader />
  ) : (
    <>
      <main className="container d-flex flex-column">
        <div style={{ position: "absolute", bottom: "1rem", right: "2rem" }}>
          <ThemeSwitcher />
          <small className="text-secondary">Bus.Vanilla V0.1.0, C01</small>
        </div>
        <div className="row align-items-center justify-content-center g-0 min-vh-100">
          <div className="col-12 col-md-8 col-lg-5 col-xxl-4 py-8 py-xl-0">
            {/* EMAIL AND USERNAME */}
            <div className="card smooth-shadow-sm">
              {isLoadingForm && <CompoLoader />}

              <div className="card-body p-6">
                <div className="mb-4">
                  <a href="/">
                    <img
                      style={{ height: "28px" }}
                      src={Medias.logo_sid_bar}
                      className="mb-2 text-inverse"
                      alt="df"
                    />
                  </a>
                  <p className="mb-6">
                    Renseignez votre identifiant pour vous connecter
                  </p>
                </div>
                {/* <!-- Form --> */}
                <form
                  onSubmit={
                    isFistStep ? handleValidateUsername : handleValidatePassword
                  }
                >
                  {/* <!-- Username --> */}
                  {isFistStep ? (
                    <div className="mb-5">
                      <label htmlFor="email" className="form-label">
                        Nom d'utilisateur
                      </label>
                      <input
                        type="text"
                        id="username"
                        className="form-control"
                        name="username"
                        placeholder="Nom d'utilisateur"
                        required=""
                        onChange={(e) => {
                          setValues({ ...values, username: e.target.value });
                        }}
                      />
                    </div>
                  ) : (
                    <div className="animate-left">
                      <div className="mb-3">
                        <label htmlFor="password" className="form-label">
                          Mot de passe
                        </label>
                        <input
                          type="password"
                          id="password"
                          className="form-control"
                          name="password"
                          placeholder="******"
                          required=""
                          onChange={(e) => {
                            setValues({
                              ...values,
                              password: e.target.value,
                            });
                          }}
                        />
                      </div>
                      {/* <!-- Checkbox --> */}
                      <div className="d-lg-flex justify-content-between align-items-center mb-4">
                        <div className="form-check custom-checkbox">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="rememberme"
                            onChange={(e) => {
                              setValues({
                                ...values,
                                rememberMe: e.target.checked,
                              });
                            }}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="rememberme"
                          >
                            Se souvenir de moi
                          </label>
                        </div>
                      </div>
                    </div>
                  )}
                  <div>
                    {/* <!-- Button --> */}
                    <div className="d-grid col-4">
                      <button type="submit" className="btn btn-primary btn-sm">
                        {isFistStep ? "Suivant" : "Se connecter"}
                      </button>
                    </div>

                    <div className="d-md-flex justify-content-end mt-4">
                      <div>
                        <Link to="#" className="text-muted fs-5">
                          J'ai oublié mon mot de passe?
                        </Link>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>

            <div className="col-12 text-center mt-5">
              <div className="text-center">
                &copy;{" "}
                <Link
                  to="https://www.linkedin.com/in/ndfranz"
                  target="_blank"
                  className="text-muted"
                >
                  NDfranz
                </Link>
                {" | "}
                <Link to="#" target="_blank" className="text-muted">
                  Condtion d'utilisation
                </Link>
                {" | "}
                <Link to="!#" target="_blank" className="text-muted">
                  Termes & conditions
                </Link>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Toaster />
    </>
  );
}
