import React, { useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import axios from "axios";
import serverConfiguration from "../app.config.json";
import LoaderDb from "../layouts/LoaderDb";
import { useAuth } from "../context";
import Cropper from "react-easy-crop";
import { generateBlobPreview, generateDownload } from "../global/cropImage";
import Medias from "../global/Medias";
import { Link } from "react-router-dom";
import { formatDateToDDMMYYYY } from "../global/DateFormater";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

const SERVER_URL =
  serverConfiguration.env === "dev"
    ? serverConfiguration.backend.dev.SERVER
    : serverConfiguration.backend.production.SERVER;

export default function Category() {
  // UTILISATEUR
  const { currentUser } = useAuth();

  // STATE MANAGEMENT
  const [showForm, setShowForm] = useState(false);
  const [isLoadingDbData, setIsLoadingDbData] = useState(true);
  const [btnLoading, setBtnLoading] = useState(true);
  const [isUpdateData, setIsUpdateData] = useState(false);
  const [values, setValues] = useState({
    name: "",
    description: "",
    image: "",
  });
  const [file, setFile] = useState(null);
  const [cropFile, setCropFile] = useState(null);
  const [image, setImage] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedArea, setCroppedArea] = useState(null);
  const [previewCroppedImage, setPreviewCroppedImage] = useState(null);
  const [dbData, setDbData] = useState([]);

  // Form handlers
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  //DOM
  const handleCancelForm = () => {
    setShowForm(false);
    setValues({
      name: "",
      description: "",
      image: "",
    });
    setPreviewCroppedImage(null);
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (values.name === "") {
      toast.error("Veuillez renseigner le champ!", {
        position: "bottom-right",
      });
      return;
    }

    const formData = new FormData(); // Utiliser FormData pour inclure les fichiers
    formData.append("name", values.name);
    formData.append("description", values.description);
    formData.append("id", values.id);
    formData.append("folder", "category");
    formData.append("image", cropFile); // Ajouter l'image dans FormData

    toast.promise(
      new Promise(async (resolve, reject) => {
        try {
          const url = isUpdateData
            ? `${SERVER_URL}/update-category`
            : `${SERVER_URL}/new-category`;

          const request = await axios.post(url, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${currentUser}`,
            },
          });

          resolve(request.data.message);

          // Réinitialiser le formulaire
          setIsUpdateData(false);
          setValues({
            name: "",
            description: "",
            image: "",
          });
          dataFetcher();
        } catch (error) {
          reject(error.response?.data?.message || "Une erreur est survenue");
        }
      }),
      {
        loading: "Enregistrement en cours!",
        success: (data) => data,
        error: (err) => err,
      },
      {
        position: "bottom-right",
        success: {
          duration: 5000,
          icon: "🔥",
        },
      }
    );
  };

  const handleRemoveTheItem = (id) => {
    confirmAlert({
      title: "Confirmation de supression",
      message: "Etes vous sûr de vouloir suprimer ces informations ?",
      buttons: [
        {
          label: "Oui",
          onClick: async () => {
            try {
              setIsLoadingDbData(true);
              await axios.post(
                `${SERVER_URL}/remove-category`, // Use the appropriate URL
                { id: id }, // Request body should be the second parameter
                {
                  headers: { Authorization: `Bearer ${currentUser}` }, // Headers should be the third parameter
                }
              );
            } catch (error) {
              console.log(error);
              toast.error("Erreur lors de la suppression!", {
                position: "bottom-right",
              });
            } finally {
              setIsLoadingDbData(false);
              dataFetcher();
            }
          },
        },
        {
          label: "Non",
        },
      ],
    });
  };
  // IMAGE CROPPER
  const handleChangeDrinkImage = (event) => {
    if (event.target.files && event.target.files.length > 0) {
      setFile(event.target.files[0]);
      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.addEventListener("load", () => {
        setImage(reader.result);
      });
    }
  };

  const onCropComplete = (croppedAreaPercentage, croppedAreaPixels) => {
    setCroppedArea(croppedAreaPixels);
  };

  const onDownload = async () => {
    if (file === null) {
      toast.warning("Veuillez choisir une avant de le rogner!", {
        position: "bottom-left",
      });
      return;
    }
    const ndfUpFile = await generateDownload(image, croppedArea, file);
    const previewRealtime = await generateBlobPreview(image, croppedArea, file);
    if (previewRealtime) {
      const previewUrl = URL.createObjectURL(previewRealtime);
      setPreviewCroppedImage(previewUrl);
    }
    console.log(ndfUpFile);
    setCropFile(ndfUpFile);
    setBtnLoading(false);
  };

  /**FETCH DATA */
  const dataFetcher = async () => {
    try {
      setIsLoadingDbData(true);
      const request = await axios.get(`${SERVER_URL}/categories`, {
        headers: { Authorization: `Bearer ${currentUser}` }, // Headers should be the third parameter
      });
      setDbData(request.data);
      console.log(request.data);
    } catch (error) {
      console.log(error);
      toast.error(error.response?.data?.message || "An error occurred", {
        position: "bottom-right",
      });
    } finally {
      setIsLoadingDbData(false);
    }
  };
  useEffect(() => {
    dataFetcher();
  }, []);

  return (
    <>
      <div className="app-content-area">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-12">
              {/* Page header */}
              <div className="d-flex justify-content-between align-items-center mb-4">
                <h3 className="mb-0">
                  {" "}
                  <i className="bi bi-grid"></i> Categories d'articles
                </h3>
              </div>
              {isLoadingDbData ? (
                <LoaderDb />
              ) : (
                <div className="row">
                  {/* liste & form */}
                  {!showForm ? (
                    <div className="col-12 animate-right">
                      <div className="card">
                        <div className="card-header d-md-flex border-bottom-0">
                          <div className="col-md-6">
                            <h5>Liste de vos categories d'articles</h5>
                          </div>
                          <div className="col-md-6 d-flex justify-content-end">
                            <div>
                              <button
                                type="button"
                                onClick={() => {
                                  setIsUpdateData(false);
                                  setShowForm(true);
                                }}
                                className="btn btn-outline-primary btn-sm"
                                title="aller à : Ajouter une nouvelle category"
                              >
                                <i className="icofont-plus"></i> Nouvelle
                                categorie
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="card-body">
                          <div className="table-responsive">
                            <table className="table table-sm">
                              <thead>
                                <tr>
                                  <th scope="col">Catégorie</th>
                                  <th scope="col">
                                    <i className="bi bi-calendar"></i> Crée le
                                  </th>
                                  <th scope="col">
                                    <i className="bi bi-gear"></i> Actions
                                  </th>
                                </tr>
                              </thead>
                              <tbody className="text-left">
                                {!isLoadingDbData ? (
                                  dbData.length === 0 ? (
                                    <tr>
                                      <td colSpan={3} className="text-center">
                                        -- Aucune informations --
                                      </td>
                                    </tr>
                                  ) : (
                                    dbData.map((d) => (
                                      <tr key={d.id}>
                                        <td>
                                          <div className=" d-flex">
                                            <Link href="#">
                                              <img
                                                src={d.image ? d.image :Medias.category}
                                                alt="Image"
                                                style={{
                                                  height: "34px",
                                                  width: "34px",
                                                  objectFit: "cover",
                                                }}
                                              />
                                            </Link>
                                            <div className="ms-3 lh-1">
                                              <h5 className="mb-0">
                                                <span className="text-inherit">
                                                  {d.name}
                                                </span>
                                              </h5>
                                              <small>{d.description}</small>
                                            </div>
                                          </div>
                                        </td>
                                        <td>
                                          {formatDateToDDMMYYYY(d.created_at)}
                                        </td>
                                        <td>
                                          <div className="">
                                            <button
                                              title="Editer les informations"
                                              className="btn btn-text btn-sm"
                                              onClick={() => {
                                                setShowForm(true);
                                                setIsUpdateData(true);
                                                setValues({
                                                  id: d.id,
                                                  name: d.name,
                                                  description: d.description,
                                                });
                                              }}
                                            >
                                              <i className="bi bi-pencil-square"></i>
                                            </button>
                                            <button
                                              title="Supprimer cet emplacement"
                                              className="btn btn-text btn-sm"
                                              onClick={() => {
                                                handleRemoveTheItem(d.id);
                                              }}
                                            >
                                              <i className="bi bi-trash3"></i>
                                            </button>
                                          </div>
                                        </td>
                                      </tr>
                                    ))
                                  )
                                ) : (
                                  <tr>
                                    <td colSpan={2} className="text-center">
                                      <div>
                                        <LoaderDb />
                                      </div>
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="col-12 animate-left">
                      <div className="card">
                        <div className="card-header d-md-flex border-bottom border-bottom-dark">
                          <div className="col-md-6">
                            Paramettrer une nouvelle categorie d'article
                          </div>
                          <div className="col-md-6 d-flex justify-content-end">
                            <div>
                              <button
                                type="button"
                                onClick={handleCancelForm}
                                className="btn btn-text btn-sm text-danger"
                                title="Annuler le formulaire et retourner à la liste des emplacements des serveurs"
                              >
                                <i className="icofont-close-circled"></i>{" "}
                                Annuler
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="card-body">
                          <form
                            className="row g-3 needs-validation ndf-transimssion"
                            onSubmit={handleFormSubmit}
                          >
                            <div className="col-md-5 col-12">
                              <div className="col-12 mb-3">
                                <label
                                  htmlFor="nameCategory"
                                  className="form-label"
                                >
                                  Nom ou titre
                                </label>
                                <input
                                  type="text"
                                  name="name"
                                  className="form-control"
                                  value={values.name}
                                  onChange={handleInputChange}
                                />
                              </div>
                              <div className="col-12 mb-3">
                                <label
                                  htmlFor="description"
                                  className="form-label"
                                >
                                  Description
                                </label>
                                <textarea
                                  value={values.description}
                                  name="description"
                                  className="form-control"
                                  placeholder="Enter description"
                                  onChange={handleInputChange}
                                />
                              </div>
                              <div className="col-12">
                                <label
                                  htmlFor="drinkCover"
                                  className="form-label"
                                >
                                  Image de couverture
                                </label>
                                <input
                                  accept="image/png, image/jpeg, image/jpg"
                                  type="file"
                                  name="image"
                                  className="form-control"
                                  onChange={handleChangeDrinkImage}
                                />
                              </div>
                            </div>

                            <div
                              className="col-md-4 col-12 rounded"
                              style={{
                                position: "relative",
                                background: `url(${Medias.partern})`,
                              }}
                            >
                              {image !== null && (
                                <Cropper
                                  image={image}
                                  crop={crop}
                                  zoom={zoom}
                                  aspect={1 / 1}
                                  zoomWithScroll={true}
                                  onCropChange={setCrop}
                                  onCropComplete={onCropComplete}
                                  onZoomChange={setZoom}
                                />
                              )}
                            </div>

                            <div className="col-md-3 col-12 rounded d-flex justify-content-center align-items-center">
                              {cropFile !== null &&
                                previewCroppedImage !== null && (
                                  <img
                                    style={{ height: "200px" }}
                                    className="shadow-sm rounded"
                                    src={previewCroppedImage}
                                    alt="cropImage"
                                  />
                                )}
                            </div>

                            <div className="col-12 justify-content-end mt-5 d-flex gap-3">
                              {image !== null && (
                                <button
                                  type="button"
                                  className="btn btn-secondary"
                                  onClick={onDownload}
                                >
                                  <i className="bi bi-crop"></i> Rogner{" "}
                                </button>
                              )}
                              <button
                                className="btn btn-success btn-sm"
                                type="submit"
                                title="Valider la commande"
                              >
                                <i className="bi bi-clipboard-check"></i>{" "}
                                Valider
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
        <Toaster />
      </div>
    </>
  );
}
