import React, { useCallback, useContext, useEffect, useState } from "react";
import Loader from "./layouts/Loader";
import serverConfiguration from "./app.config.json";
import axios from "axios";
const SERVER_URL =
  serverConfiguration.env === "dev"
    ? serverConfiguration.backend.dev.SERVER
    : serverConfiguration.backend.production.SERVER;

const AuthContext = React.createContext();

export const useAuth = () => {
  return useContext(AuthContext);
};

export function AuthProvider({ children }) {
  const [loading, setLoading] = useState(true);
  const [currentUser, setCurrentUser] = useState(null);
  const [userCredentials, setUserCredentials] = useState(null);

  /**
   * CHANGE USERNAME
   */
  const changeUserName = async (obj) => {
    try {
      const user = JSON.parse(sessionStorage.getItem("user"));
      const request = await axios.post(
        `${SERVER_URL}/change-username`,
        obj, // Request body should be the second parameter
        {
          headers: { Authorization: `Bearer ${user}` }, // Headers should be the third parameter
        }
      );
      // Save user token
      return request.data;
    } catch (error) {
      throw error; // Re-throw error to handle it in _handleSubmit
    }
  };

  /**
   * CHANGE PASSWORD
   */
  const changePassword = async (obj) => {
    try {
      const user = JSON.parse(sessionStorage.getItem("user"));
      const request = await axios.post(
        `${SERVER_URL}/change-password`,
        obj, // Request body should be the second parameter
        {
          headers: { Authorization: `Bearer ${user}` }, // Headers should be the third parameter
        }
      );
      // Save user token
      return request.data;
    } catch (error) {
      throw error; // Re-throw error to handle it in _handleSubmit
    }
  };

  /**
   * Sign in with email & password
   * @param {object} obj form data
   */
  const validateUsername = async (obj) => {
    try {
      const authReq = await axios.post(
        `${SERVER_URL}/validate-username`,
        obj
      );
      return authReq.data;
    } catch (error) {
      console.log(error)
      throw error; // Re-throw error to handle it in _handleSubmit
    }
  };

  /**
   * Sign in with email & password
   * @param {object} obj form data
   */
  const validatePassword = async (obj) => {
    try {
      const authReq = await axios.post(
        `${SERVER_URL}/validate-password`,
        obj
      );
      // Save user token
      sessionStorage.setItem("user", JSON.stringify(authReq.data.token));
      setCurrentUser(authReq.data.token);
      await onAuthStateChanged();
      if (authReq.data.is_first_attemps_login) {
        return authReq.data;
      }
      return { message: "Compte accepté" };
    } catch (error) {
      console.log(error)
      throw error; // Re-throw error to handle it in _handleSubmit
    }
  };

  /**
   * LOGOUT
   */
  const logout = useCallback(async () => {
    try {
      sessionStorage.removeItem("user");
      sessionStorage.removeItem("currentUser");
      setCurrentUser(null);
    } catch (error) {
      console.log("Logout error =>", error);
    } finally {
      setLoading(false);
    }
  }, []);

  /**
   * AuthStatechanged
   */
  const onAuthStateChanged = useCallback(async () => {
    try {
      setLoading(true);
      const user = JSON.parse(sessionStorage.getItem("user"));

      if (!user) {
        setCurrentUser(null);
        return;
      }

      const tokenResponse = await axios.get(
        `${SERVER_URL}/auth-state`,
        {
          headers: { Authorization: `Bearer ${user}` },
        }
      );
      sessionStorage.setItem(
        "currentUser",
        JSON.stringify(tokenResponse.data.userCredentials)
      );
      setUserCredentials(tokenResponse.data.userCredentials)
      setCurrentUser(user);
      // console.log("context =>", tokenResponse.data)
      return tokenResponse;
    } catch (error) {
      console.log(error);
      logout();
      return error;
    } finally {
      setLoading(false);
    }
  }, [logout]);

  useEffect(() => {
    onAuthStateChanged();
    return () => {
      onAuthStateChanged();
      // console.log("useEffect =>",loading);
    };
  }, []);

  return loading ? (
    <Loader />
  ) : (
    <AuthContext.Provider
      value={{
        currentUser,
        userCredentials,
        validatePassword,
        validateUsername,
        logout,
        changeUserName,
        changePassword,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}