import React, { useEffect, useState } from "react";
import config from "../app.config.json";
import axios from "axios";
import Media from "../global/Medias";
import { useAuth } from "../context";
import { Link } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";

export default function Home() {
  const { userCredentials, currentUser } = useAuth();

  const [isLoadingDbData, setIsLoadingDbData] = useState(true);
  const [dbData, setDbData] = useState({});

  const getDevicePercentages = () => {
    const { total, deviceCount } = dbData.visitorsDevice;

    // Vérification pour éviter la division par zéro
    if (total === 0) {
      return {
        phone: 0,
        tablet: 0,
        computer: 0,
      };
    }

    return {
      phone: (deviceCount.phone / total) * 100,
      tablet: (deviceCount.tablet / total) * 100,
      computer: (deviceCount.computer / total) * 100,
    };
  };

  /**FETCH DATA */
  const dataFetcher = async () => {
    try {
      const request = await axios.get(
        `${config.backend.dev.SERVER}/dashboard`,
        {
          headers: { Authorization: `Bearer ${currentUser}` }, // Headers should be the third parameter
        }
      );
      setDbData(request.data);
      // console.log(request.data);
      setIsLoadingDbData(false);
    } catch (error) {
      console.log(error);
      toast.error(error.response?.data?.message || "An error occurred", {
        position: "bottom-right",
      });
    }
  };

  // useEffect(() => {
  //   dataFetcher();
  // }, []);
  return (
    <>
      <div className="app-content-area animate-bottom">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-12">
              {/* <!-- Page header --> */}
              <div className="d-flex justify-content-between align-items-center mb-4">
                <h3 className="mb-0">
                  <i className="bi bi-house"></i> Tableau de bord
                </h3>
              </div>

              <div className="bg-primary rounded-3">
                <div className="row mb-5">
                  <div className="col-lg-12 col-md-12 col-12">
                    <div className="p-6 d-lg-flex justify-content-between align-items-center">
                      <div className="d-md-flex align-items-center">
                        <img
                          src={Media.userWhite}
                          alt="Image"
                          className="rounded-circle avatar avatar-xl"
                        />
                        <div className="ms-md-4  mt-3 mt-md-0 lh-1">
                          <h3 className="text-white mb-0">
                            {userCredentials.username}
                          </h3>
                          <small className="text-white col-md-6">
                            Vous pouvez commencer par consulter les
                            statistiques, <br />
                            mettre à jour les informations ou explorer les
                            nouvelles fonctionnalités disponibles.
                          </small>
                        </div>
                      </div>
                      <div className="d-none d-lg-block">
                        <Link to="/tickets" className="btn btn-white">
                          <i className="bi bi-ticket"></i>{" "}Gestions des tickets
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {!isLoadingDbData && (
                <div className="row">
                  <div className="col-xl-6 col-md-12 col-12 mb-5">
                    <div className="row row-cols-lg-2 row-cols-1 g-5">
                      <div className="col">
                        <div className="card h-100 card-lift">
                          <div className="card-body">
                            <div className="d-flex justify-content-between align-items-center">
                              <span className="fw-semi-bold">
                                Tickets vendus
                              </span>
                              <span>
                                <i className="bi bi-ticket font-fz text-gray-400"></i>
                              </span>
                            </div>
                            <div className="mt-4 mb-2">
                              <h3 className="fw-bold mb-0">{dbData.tickets}</h3>
                            </div>
                            <span className="text-primary">
                              <i className="icofont-users icofont-duotone"></i>{" "}
                              Acheteur(s): {dbData.visitors}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="col">
                        <div className="card h-100 card-lift">
                          <div className="card-body">
                            <div className="d-flex justify-content-between align-items-center">
                              <span className="fw-semi-bold">Codes Promos</span>
                              <span>
                                <i className="bi bi-gift font-fz text-gray-400"></i>
                              </span>
                            </div>
                            <div className="mt-4 mb-2">
                              <h3 className="fw-bold mb-0">
                                {+dbData.promocodes.unused +
                                  +dbData.promocodes.used}
                              </h3>
                            </div>
                            <span className="text-danger">
                              <i className="icofont-refresh"></i> Utilisé:{" "}
                              {+dbData.promocodes.used}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="col">
                        <div className="card h-100 card-lift">
                          <div className="card-body">
                            <div className="d-flex justify-content-between align-items-center">
                              <span className="fw-semi-bold">
                                Plans Tarifaires
                              </span>
                              <span>
                                <i className="bi bi-tags font-fz text-gray-400"></i>
                              </span>
                            </div>
                            <div className="mt-4 mb-2">
                              <h3 className="fw-bold mb-0">{dbData.plans}</h3>
                            </div>
                            <span className="text-primary">
                              <i className="bi bi-tags"></i> Le plus vendu:{" "}
                              <span className="text-danger">
                                {/* {dbData.theBestPlan.name} */}
                              </span>
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="col">
                        <div className="card h-100 card-lift">
                          <div className="card-body">
                            <div className="d-flex justify-content-between align-items-center">
                              <span className="fw-semi-bold">
                                Erreurs de transactions
                              </span>
                              <span>
                                <i className="bi bi-bug font-fz text-gray-400"></i>
                              </span>
                            </div>
                            <div className="mt-4 mb-2">
                              <h3 className="fw-bold mb-0 text-danger">
                                {dbData.logs}
                              </h3>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-6 col-md-12 col-12 mb-5">
                    <div className="card h-100">
                      <div className="card-body">
                        <h4 className="mb-0">Visiteurs par type</h4>
                        <div className="row row-cols-lg-3 my-8">
                          <div className="col">
                            <div>
                              <h4 className="mb-3">
                                <i className="bi bi-laptop"></i> D,L - top
                              </h4>
                              <div className="lh-1">
                                <h4 className="fs-2 fw-bold text-info mb-0">
                                  {parseInt(getDevicePercentages().computer) + "%"}
                                </h4>
                                <span className="text-info">
                                  {dbData.visitorsDevice.deviceCount.computer}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="col">
                            <div>
                              <h4 className="mb-3"><i className="bi bi-phone"></i> Mobile</h4>
                              <div className="lh-1">
                                <h4 className="fs-2 fw-bold text-success mb-0">
                                  {parseInt(getDevicePercentages().phone) + "%"}
                                </h4>
                                <span className="text-success">
                                  {dbData.visitorsDevice.deviceCount.phone}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="col">
                            <div>
                              <h4 className="mb-3"> <i className="bi bi-tablet-landscape"></i> Tablet</h4>
                              <div className="lh-1">
                                <h4 className="fs-2 fw-bold text-warning mb-0">
                                  {parseInt(getDevicePercentages().tablet) + "%"}
                                </h4>
                                <span className="text-warning">
                                  {dbData.visitorsDevice.deviceCount.tablet}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="mt-6 mb-3">
                          <div className="progress" style={{ height: "40px" }}>
                            <div
                              className="progress-bar bg-info"
                              role="progressbar"
                              aria-label="Segment one"
                              style={{
                                width: getDevicePercentages().computer + "%",
                              }}
                              aria-valuenow={
                                getDevicePercentages().computer + "%"
                              }
                              aria-valuemin="0"
                              aria-valuemax="100"
                            ></div>
                            <div
                              className="progress-bar bg-success"
                              role="progressbar"
                              aria-label="Segment two"
                              style={{
                                width: getDevicePercentages().phone + "%",
                              }}
                              aria-valuenow={getDevicePercentages().phone + "%"}
                              aria-valuemin="0"
                              aria-valuemax="100"
                            ></div>
                            <div
                              className="progress-bar bg-warning"
                              role="progressbar"
                              aria-label="Segment three"
                              style={{
                                width: getDevicePercentages().tablet + "%",
                              }}
                              aria-valuenow={
                                getDevicePercentages().tablet + "%"
                              }
                              aria-valuemin="0"
                              aria-valuemax="100"
                            ></div>
                          </div>
                        </div>
                        <div className="d-flex justify-content-between">
                          <div className="col-6">
                            <span className="h6">VISITEUR TOTAL </span>
                            <span className="badge bg-warning px-3">
                              {dbData.visitorsDevice.total}
                            </span>
                          </div>
                          <div className="col-6 d-flex justify-content-end">
                            <button
                              type="button"
                              title="Reactualiser"
                              className="btn btn-icon btn-white rounded-circle"
                              onClick={() => {
                                dataFetcher();
                              }}
                            >
                              <i className="icofont-refresh"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <Toaster />
      </div>
    </>
  );
}
