import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "react-perfect-scrollbar/dist/css/styles.css";
import toast, { Toaster } from "react-hot-toast";
import Loader from "./layouts/Loader";
import Header from "./layouts/Header";
import { useAuth } from "./context";
import SidBar from "./layouts/Sidbar";

export default function PrivateRoute({ children }) {
  const navigation = useNavigate();
  const [isLoading, setIsloading] = useState(true);
  const [isToggle, setIsToggle] = useState(false);
  const { currentUser } = useAuth();

  useEffect(() => {
    setIsloading(true);
    if (!currentUser) {
      toast.error("Votre session est expirée!", {
        autoClose: false,
        position: 'top-right'
      });
      navigation("/login", { replace: true });
      setIsloading(false);
    } else {
      setIsloading(false);
    }
  }, [navigation]);

  return isLoading ? (
    <>
      <Loader />
    </>
  ) : (
    <>
      <main
        id="main-wrapper"
        className={`main-wrapper ${isToggle ? "toggled" : ""}`}
      >
        {/* header */}
        <Header isToggle={isToggle} setIsToggle={setIsToggle} />
        {/* header */}

        {/* SidBar */}
        <SidBar />
        {/* SidBar */}

        {/* APP */}
        <div id="app-content" >{children}</div>
        {/* APP */}
        <Toaster/>
      </main>
    </>
  );
}
