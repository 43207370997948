import React, { useState } from "react";
import toast, { Toaster } from "react-hot-toast";

export default function Celling() {
  const [articles, setArticles] = useState([
    {
      id: 1,
      name: "Women Shoes",
      subcategory: "Shoes",
      price: 10000,
      stock: 10,
      quantity: 1,
      image:
        "https://bus-c1-app.allwhiteworld.com/images/category/BUS1733939799913-C644679053.png",
    },
    {
      id: 2,
      name: "Sac a main",
      subcategory: "bag",
      price: 25000,
      stock: 5,
      quantity: 1,
      image:
        "https://bus-c1-app.allwhiteworld.com/images/subcategory/BUS1733963417903-C381977804.png",
    },
  ]);

  const [cart, setCart] = useState([]);
  const [clientMoney, setClientMoney] = useState(0);
  const [discount, setDiscount] = useState(0);

  const removeFromCart = (id) => {
    setCart((prevCart) => prevCart.filter((item) => item.id !== id));
    toast.success("Article retiré du panier");
  };

  // Incrémente la quantité d'un article
  const incrementQuantity = (id) => {
    setArticles((prevArticles) =>
      prevArticles.map((article) =>
        article.id === id && article.quantity < article.stock
          ? { ...article, quantity: article.quantity + 1 }
          : article
      )
    );
  };

  // Décrémente la quantité d'un article
  const decrementQuantity = (id) => {
    setArticles((prevArticles) =>
      prevArticles.map((article) =>
        article.id === id && article.quantity > 1
          ? { ...article, quantity: article.quantity - 1 }
          : article
      )
    );
  };

  // Ajoute un article au panier
  const addToCart = (article) => {
    const exists = cart.find((item) => item.id === article.id);
    console.log(exists);
    if (exists) {
      if (exists.quantity + article.quantity <= article.stock) {
        setCart((prevCart) =>
          prevCart.map((item) =>
            item.id === article.id
              ? { ...item, quantity: item.quantity + article.quantity }
              : item
          )
        );
      }
    } else {
      setCart([...cart, article]);
    }
    toast.success(`${article.name} ajouté au panier`);
  };

  const calculateTotalPrice = () => {
    const total = cart.reduce(
      (sum, item) => sum + item.price * item.quantity,
      0
    );
    return total - discount > 0 ? total - discount : 0;
  };

  const calculateRelicat = () => {
    const total = calculateTotalPrice();
    return clientMoney - total > 0 ? clientMoney - total : 0;
  };

  return (
    <>
      <div className="app-content-area">
        <div className="container-fluid">
          {/* Liste des articles */}
          <div className="row">
            <div className="col-lg-8 col-12">
              {/* SEARCH */}
              <div class="card mb-4">
                <div class="card-body">
                  <div className="d-flex align-items-end gap-3">
                    <div class="mb-3 col">
                      <label class="form-label">
                        <i className="bi bi-search"></i> Rechercher un article
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Désignation, sous-catégorie, ou catégorie"
                        required=""
                      />
                    </div>
                    <div className="mb-3">
                      <button type="button" className="btn btn-primary">
                        <i className="bi bi-search"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              {/* /SEARCH */}

              <div className="card mb-4">
                <div className="card-body px-0">
                  <div className="table-responsive rounded">
                    <table className="table text-nowrap table-centered">
                      <thead>
                        <tr>
                          <th>Désignation</th>
                          <th>Prix</th>
                          <th>Stock</th>
                          <th>Quantité</th>
                        </tr>
                      </thead>
                      <tbody>
                        {articles.map((article) => (
                          <tr key={article.id}>
                            <td>
                              <div className="d-flex align-items-center">
                                <img
                                  src={article.image}
                                  alt=""
                                  className="img-4by3-sm rounded-3"
                                />
                                <div className="ms-3">
                                  <h5 className="mb-0">{article.name}</h5>
                                  <span>{article.subcategory}</span>
                                </div>
                              </div>
                            </td>
                            <td>{article.price.toLocaleString()}</td>
                            <td>{article.stock}</td>
                            <td>
                              <div className="d-flex align-items-center">
                                <div className="input-group input-spinner">
                                  <button
                                    className="button-minus btn btn-sm"
                                    onClick={() =>
                                      decrementQuantity(article.id)
                                    }
                                  >
                                    -
                                  </button>
                                  <input
                                    type="number"
                                    value={article.quantity}
                                    readOnly
                                    className="quantity-field form-control-sm form-input"
                                  />
                                  <button
                                    className="button-plus btn btn-sm"
                                    onClick={() =>
                                      incrementQuantity(article.id)
                                    }
                                  >
                                    +
                                  </button>
                                </div>
                                <div>
                                  <button
                                    className="btn btn-primary btn-sm"
                                    onClick={() => addToCart(article)}
                                  >
                                    Ajouter
                                  </button>
                                </div>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>

            {/* Panier */}
            <div className="col-lg-4 col-12">
              <div className="card mb-4">
                <div className="card-header py-2">
                  <h4 className="mb-0">
                    <i className="bi bi-cart"></i> Panier
                  </h4>
                </div>
                <div className="card-body px-0 pt-0">
                  <table className="table text-nowrap table-centered">
                    <thead>
                      <tr>
                        <th>Désignation</th>
                        <th>Qte</th>
                      </tr>
                    </thead>
                    <tbody>
                      {cart.map((item) => (
                        <tr key={item.id}>
                          <td>
                            <div className="d-flex align-items-center">
                              <img
                                src={item.image}
                                alt=""
                                className="rounded-3"
                                style={{ height: "34px", width: "34px" }}
                              />
                              <div className="ms-3">
                                <h5 className="mb-0">{item.name}</h5>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className="d-flex gap-2 align-items-center">
                              <span>{item.quantity}</span>
                              <button
                                className="btn btn-ghost btn-icon btn-sm rounded-circle texttooltip"
                                title="supprimer du panier"
                                onClick={() => removeFromCart(item.id)}
                              >
                                <i className="bi bi-trash"></i>
                              </button>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
              {/* Valeur */}
              <div className="card mb-4">
                <div className="p-3 d-flex justify-content-between">
                  <h4 className="text-uppercase">Total</h4>
                  <h4 className="text-dark">{calculateTotalPrice()} FCFA</h4>
                </div>
              </div>
              {/* Valeur */}
              {/* Client */}
              <div className="card mb-4">
                <div className="card-header py-2">
                  <h4 className="mb-0">
                    <i className="bi bi-person"></i> Client
                  </h4>
                </div>
                <div className="card-body">
                  <div className="form-group mb-3">
                    <label htmlFor="clientMoney">Montant du client</label>
                    <input
                      type="number"
                      className="form-control"
                      id="clientMoney"
                      name="clientMoney"
                      min={0}
                      value={clientMoney}
                      onChange={(e) => setClientMoney(Number(e.target.value))}
                    />
                  </div>
                  <div className="form-group mb-3">
                    <label htmlFor="discount">Remise</label>
                    <input
                      type="number"
                      className="form-control"
                      id="discount"
                      name="discount"
                      min={0}
                      value={discount}
                      onChange={(e) => setDiscount(Number(e.target.value))}
                    />
                  </div>
                  <div className="form-group mb-3">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        value="espece"
                        id="espece"
                        name="money"
                      />
                      <label className="form-check-label" htmlFor="espece">
                        Espèce
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        value="flooz"
                        id="flooz"
                        name="money"
                      />
                      <label className="form-check-label" htmlFor="flooz">
                        Flooz / Tmoney
                      </label>
                    </div>
                  </div>
                  <hr />
                  <div className="d-flex justify-content-between">
                    <h4 className="text-uppercase">Relicat</h4>
                    <h4 className="text-dark">{calculateRelicat()} FCFA</h4>
                  </div>
                </div>
              </div>
              {/* client */}

              <div className="d-grid">
                <button type="button" className="btn btn-primary" disabled>
                  Valider la vente
                </button>
              </div>
            </div>
          </div>
        </div>
        <Toaster />
      </div>
    </>
  );
}
