import React from "react";
import PrivateRoute from "./privates-routes";
import Login from "./app/Login";
import Home from "./app/Home";
import Profile from "./app/Profile";
import Roles from "./app/Roles";
import Permissions from "./app/Permissions";
import RoleHasPermissions from "./app/RoleHasPermissions";
import Users from "./app/Users";
import Category from "./app/Category";
import SubCategory from "./app/SubCategory";
import Produces from "./app/Produces";
import PayementMethods from "./app/PayementMethods";
import Celling from "./app/Celling";
import CellingRepport from "./app/CellingRepport";
import AprovRepport from "./app/AprovRepport";

export const routes = [
  { path: "/", element: <PrivateRoute><Home /></PrivateRoute> },
  { path: "/*", element: <PrivateRoute><Home /></PrivateRoute> },
  { path: "/settings", element: <PrivateRoute><Profile /></PrivateRoute> },
  { path: "/roles", element: <PrivateRoute><Roles /></PrivateRoute> },
  { path: "/permissions", element: <PrivateRoute><Permissions /></PrivateRoute> },
  { path: "/role-has-permission", element: <PrivateRoute><RoleHasPermissions /></PrivateRoute> },
  { path: "/users", element: <PrivateRoute><Users /></PrivateRoute> },
  { path: "/category", element: <PrivateRoute><Category /></PrivateRoute> },
  { path: "/sub-category", element: <PrivateRoute><SubCategory /></PrivateRoute> },
  { path: "/produces", element: <PrivateRoute><Produces /></PrivateRoute> },
  { path: "/payements-methodes", element: <PrivateRoute><PayementMethods /></PrivateRoute> },
  { path: "/celling", element: <PrivateRoute><Celling /></PrivateRoute> },
  { path: "/celling-repport", element: <PrivateRoute><CellingRepport /></PrivateRoute> },
  { path: "/aprov-repport", element: <PrivateRoute><AprovRepport /></PrivateRoute> },

  { path: "/login", Component: Login },
];
