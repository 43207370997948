import React, { useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import axios from "axios";
import config from "../app.config.json";
import { useAuth } from "../context";
import PermissionsTable from "./inc/PermissionsTable";

export default function Permissions() {
  // USER
  const { currentUser } = useAuth();
  const [showForm, setShowForm] = useState(false);
  // DATA OF INPUT
  const [values, setValues] = useState({
    actions: [],
    table_name: "",
    role_id: "",
  });
  const [isUpdateData, setIsUpdateData] = useState(false);
  const [isLoadingDbData, setIsLoadingDbData] = useState(false);
  const [dbData, setDbData] = useState([]);
  const [dataRoles, setDataRoles] = useState([]);

  const handleSubmitForm = (e) => {
    e.preventDefault();
    if (values.table_name === "") {
      toast.error("Veuillez renseigner le champs!", {
        position: "bottom-right",
      });
      return;
    }
    toast.promise(
      new Promise(async (resolve, reject) => {
        try {
          // Set URL based on whether it's an update or new data
          const url = isUpdateData
            ? `${config.backend.dev.SERVER}/update-permissions`
            : `${config.backend.dev.SERVER}/new-permission`;

          const request = await axios.post(
            url, // Use the appropriate URL
            values, // Request body should be the second parameter
            {
              headers: { Authorization: `Bearer ${currentUser}` }, // Headers should be the third parameter
            }
          );

          // console.log(request);
          resolve(request.data.message);
          dataFetcher();

          // Clean form DOM
          setIsUpdateData(false);
          setValues({
            actions: [],
            table_name: "",
            role_id: "",
          });
        } catch (error) {
          console.log(error);
          reject(error.response?.data?.message || "An error occurred");
        }
      }),

      {
        loading: "Enregistrement en cours!",
        success: (data) => data,
        error: (err) => err,
      },
      {
        position: "bottom-right",
        success: {
          duration: 5000,
          icon: "🔥",
        },
      }
    );
  };

  const handleRemoveTheRole = async (id) => {
    try {
      setIsLoadingDbData(true);
      await axios.post(
        `${config.backend.dev.SERVER}/remove-permissions`, // Use the appropriate URL
        { permission_id: id }, // Request body should be the second parameter
        {
          headers: { Authorization: `Bearer ${currentUser}` }, // Headers should be the third parameter
        }
      );
    } catch (error) {
      console.log(error);
      toast.error("Erreur de chargement des données", {
        position: "bottom-right",
      });
    } finally {
      setIsLoadingDbData(false);
      dataFetcher();
    }
  };

  /**FETCH DATA */
  const dataFetcher = async () => {
    try {
      const request = await axios.get(
        `${config.backend.dev.SERVER}/get-role-has-permissions`,
        {
          headers: { Authorization: `Bearer ${currentUser}` }, // Headers should be the third parameter
        }
      );
      const requestRoles = await axios.get(
        `${config.backend.dev.SERVER}/get-roles`,
        {
          headers: { Authorization: `Bearer ${currentUser}` }, // Headers should be the third parameter
        }
      );
      setDbData(request.data);
      setDataRoles(requestRoles.data);
      // console.log(requestRoles.data);
    } catch (error) {
      toast.error("Erreur de chargement des données", {
        position: "bottom-right",
      });
    } finally {
      setIsLoadingDbData(false);
    }
  };
  useEffect(() => {
    dataFetcher();
  }, []);

  return (
    <div className="app-content-area">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-12">
            {/* <!-- Page header --> */}
            <div className="d-flex justify-content-between align-items-center mb-4">
              <h3 className="mb-0">
                <i className="icofont-duotone icofont-workflows"></i> Gerer les
                permissions du système
              </h3>
            </div>
          </div>

          {/* app */}
          {!showForm ? (
            <div className="col-12 animate-right">
              <div className="card">
                <div className="card-header d-md-flex border-bottom">
                  <div className="col-md-6">
                    <h5>Liste & option de gestion des roles</h5>
                  </div>
                  <div className="col-md-6 d-flex justify-content-end">
                    <div>
                      <button
                        type="button"
                        className="btn btn-outline-primary btn-sm"
                        title="Gerer des permissions"
                        onClick={() => {
                          setValues({
                            actions: [],
                            table_name: "",
                            role_id: "",
                          });
                          setShowForm(true);
                        }}
                      >
                        <i className="icofont-plus"></i> Nouvelle permission
                      </button>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="table-responsive col-12">
                      {dbData.length > 0 && (
                        <PermissionsTable
                          permissions={dbData}
                          values={values}
                          setValues={setValues}
                          setShowForm={setShowForm}
                          setIsUpdateData={setIsUpdateData}
                          handleRemoveTheRole={handleRemoveTheRole}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="col-12 animate-left">
              <div className="card">
                <div className="card-header d-md-flex border-bottom-0 border-bottom-dark">
                  <div className="col-md-4">
                    Paramétrez les permission d'un rôle
                  </div>
                  <div className="col-md-8 d-flex justify-content-end">
                    <div>
                      <button
                        type="button"
                        onClick={() => {
                          setValues({
                            actions: [],
                            table_name: "",
                            role_id: "",
                          });
                          setShowForm(false);
                        }}
                        className="btn btn-text btn-sm text-danger"
                        title="Annuler le formulaire et retourner à la liste"
                      >
                        <i className="icofont-close-circled"></i> Annuler
                      </button>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <form
                    className="row g-5 needs-validation"
                    onSubmit={handleSubmitForm}
                  >
                    <div className="col-5 row">
                      <div className="col-12 mb-4">
                        <label htmlFor="tableName" className="form-label">
                          Zone d'accès
                        </label>
                        <select
                          className="form-control"
                          id="tableName"
                          required
                          value={values.table_name}
                          onChange={(e) =>
                            setValues({ ...values, table_name: e.target.value })
                          }
                        >
                          <option value="">
                            -- Sélectionnez la zone d'accès --
                          </option>
                          {config.tables.map((e, index) => (
                            <option key={index} value={e.value}>
                              {e.name}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="col-12">
                        <label htmlFor="role" className="form-label">
                          Rôle
                        </label>
                        <select
                          className="form-control"
                          id="role"
                          required
                          value={values.role_id}
                          onChange={(e) =>
                            setValues({ ...values, role_id: e.target.value })
                          }
                        >
                          <option value="">-- Sélectionnez le role --</option>
                          {!isLoadingDbData &&
                            dataRoles.map((e, index) => (
                              <option key={index} value={e.role_id}>
                                {e.name}
                              </option>
                            ))}
                        </select>
                      </div>
                    </div>

                    <div className="col-md-7 mt-0">
                      <label
                        htmlFor="validationCustom01"
                        className="form-label"
                      >
                        Actions autorisées dans la zone d'accès
                      </label>
                      <div className="row">
                        <div className="col">
                          <p className="mb-1 text-italic">Lecture</p>
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="read"
                              checked={values.actions.includes("R")}
                              onChange={(e) => {
                                const isChecked = e.target.checked;
                                setValues((prevValues) => ({
                                  ...prevValues,
                                  actions: isChecked
                                    ? [...prevValues.actions, "R"]
                                    : prevValues.actions.filter(
                                        (action) => action !== "R"
                                      ),
                                }));
                              }}
                            />
                            <label className="form-check-label" htmlFor="read">
                              Lecture
                            </label>
                          </div>
                        </div>
                        <div className="col-9">
                          <p className="mb-1 text-italic">Ecriture(s)</p>
                          <div className="d-flex">
                            <div className="form-check form-check-inline col-4">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="add"
                                checked={values.actions.includes("C")}
                                onChange={(e) => {
                                  const isChecked = e.target.checked;
                                  setValues((prevValues) => ({
                                    ...prevValues,
                                    actions: isChecked
                                      ? [...prevValues.actions, "C"]
                                      : prevValues.actions.filter(
                                          (action) => action !== "C"
                                        ),
                                  }));
                                }}
                              />
                              <label className="form-check-label" htmlFor="add">
                                Ajout
                              </label>
                            </div>
                            <div className="form-check form-check-inline col-4">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="update"
                                checked={values.actions.includes("U")}
                                onChange={(e) => {
                                  const isChecked = e.target.checked;
                                  setValues((prevValues) => ({
                                    ...prevValues,
                                    actions: isChecked
                                      ? [...prevValues.actions, "U"]
                                      : prevValues.actions.filter(
                                          (action) => action !== "U"
                                        ),
                                  }));
                                }}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="update"
                              >
                                Modification
                              </label>
                            </div>
                            <div className="form-check form-check-inline col-4">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="delete"
                                checked={values.actions.includes("D")}
                                onChange={(e) => {
                                  const isChecked = e.target.checked;
                                  setValues((prevValues) => ({
                                    ...prevValues,
                                    actions: isChecked
                                      ? [...prevValues.actions, "D"]
                                      : prevValues.actions.filter(
                                          (action) => action !== "D"
                                        ),
                                  }));
                                }}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="delete"
                              >
                                Suppression
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-12 text-end">
                      <button className="btn btn-success btn-sm" type="submit">
                        <i className="icofont-save icofont-duotone"></i>{" "}
                        {!isUpdateData
                          ? "Enregistrer"
                          : "Enregistrer les mises à jour"}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          )}
          {/* app */}
        </div>
      </div>
      <Toaster />
    </div>
  );
}
